<template>
  <div>
    <div class="content-px mb-75">
      <b-row>
        <b-col cols="12" sm="8" md="7">
          <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
            <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 28rem" />
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="5" class="d-flex align-items-center justify-content-end"> </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <div no-body class="mb-0 table-no-top">
      <b-table
        ref="refStudentListTable"
        class="position-relative mb-0"
        :items="fetchStudents"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Student -->
        <template #cell(student)="data">
          <div class="d-flex align-items-center" v-if="data.item.student" style="gap: 0.625rem">
            <b-avatar
              size="36"
              :src="data.item.student.avatar"
              :text="avatarText(`${data.item.student.firstName} ${data.item.student.lastName}`)"
              :variant="`light-info`"
              :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
            />
            <div class="d-flex flex-column" style="gap: 0">
              <b-link
                :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
                class="font-weight-bold text-nowrap text-white mb-0"
                style="font-size: 1.25rem; line-height: 1.75rem"
              >
                {{ data.item.student.fullName }}
              </b-link>
              <small class="text-muted" style="font-size: 1.05rem; line-height: 1.35rem">{{ data.item.student.myIpId }}</small>
            </div>
          </div>
          <p v-else>Corrupted Student, Contact Support</p>
        </template>

        <!-- Column: Role -->
        <template #cell(blacklist.reason)="data">
          <div class="text-nowrap">
            {{ con(data.item.blacklist.reason) }}
          </div>
        </template>

        <!-- Column: Date of Class -->
        <template #cell(blacklist.timestamp)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ momentTz(data.item.blacklist.timestamp).format('L') }}
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveStudentStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex align-items-center pl-25">
            <feather-icon
              icon="EditIcon"
              size="16"
              class="cursor-pointer mr-50"
              v-if="$can('student.update')"
              @click="$router.push({ name: 'apps-students-edit', params: { id: data.item.student.id } })"
            />
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="cursor-pointer mr-50"
              @click="$router.push({ name: 'apps-students-view', params: { id: data.item.student.id } })"
            />
            <feather-icon
              icon="DeleteIcon"
              size="16"
              class="cursor-pointer"
              v-if="$can('student.blacklist.remove')"
              @click="unblacklistStudent(data.item.student.id)"
            />
            <!-- <feather-icon icon="TrashIcon" size="16" class="cursor-pointer" @click="archivePathway(data.item.id)" /> -->
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-50">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import useStudentsBlacklist from './useStudentsBlacklist';
import studentStoreModule from '../../../../store/student/studentStoreModule';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { momentTz } from '@/libs/utils/time';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    con(reason) {
      if (!reason) return 'n/a';
      if (reason.length < 128) return reason;
      return reason.substring(0, 128) + '...';
    },
    unblacklistStudent(studentId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to unblacklist this student?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Forgive Them',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('studentStoreModule/updateStudentProfile', {
              id: studentId,
              profileData: { blacklist: { enabled: false } },
            })
            .then((response) => {
              this.refetchData();

              this.$swal({
                icon: 'success',
                title: 'Un-Blacklisted!',
                text: 'This student has been removed from the blacklist',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
        }
      });
    },
    doExport(ids) {
      this.$http
        .post(`v1/jobs/export`, { entity: 'resume', metadata: { ids } })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,

            props: {
              title: `Job Started`,
              icon: 'SettingsIcon',
              variant: 'success',
              text: `Your job has been queued for execution. You may leave the site while it is prepared.`,
            },
          });

          this.$router.push({ name: 'apps-job-list-pending' });
        })
        .catch((e) => {
          this.processing = false;
          console.error(e);
        });
    },

    async selectNextPage(page, payload) {
      return new Promise((resolve, reject) => {
        store
          .dispatch('studentStoreModule/fetch', { ...payload, page: page })
          .then(resolve)
          .catch(reject);
      });
    },
    async doAllPdfExport() {
      this.processing = true;
      try {
        this.$toast({
          component: ToastificationContent,

          props: {
            title: `Job Build Started`,
            icon: 'SettingsIcon',
            variant: 'success',
            text: `This may take a moment to generate the job settings, please wait...`,
          },
        });

        const { searchQuery, statusFilter, pathwayFilter, createAtStartFilter, createAtEndFilter } = this;

        const pl = {
          search: searchQuery,
          limit: 100,
        };

        if (statusFilter) {
          pl.status = statusFilter;
        }
        if (pathwayFilter) {
          pl.programName = pathwayFilter;
        }
        // if (createAtStartFilter) {
        //   pl.createAtStart = createAtStartFilter;
        // }
        // if (createAtEndFilter) {
        //   pl.createAtEnd = createAtEndFilter;
        // }

        let currentPage = 1;
        let lastPage = 2;

        let allIds = [];
        let currentFail = 0;

        while (currentPage <= lastPage || currentFail > 5) {
          try {
            const response = await this.selectNextPage(currentPage, pl);

            if (response.status !== 200) {
              currentPage -= 1;
              currentFail += 1;
              continue;
            }

            let { results, totalPages } = response.data;

            console.log(results);
            results.forEach((report) => allIds.push(report.id));

            lastPage = totalPages;
            currentPage += 1;
            currentFail = 0;
          } catch (e) {
            currentFail += 1;
            currentPage -= 1;
          }
        }

        allIds = allIds.filter((value, index, self) => self.indexOf(value) === index);

        this.doExport(allIds);
      } catch (e) {
        console.error(e);
      }

      this.processing = false;
    },
  },
  data: () => {
    return {
      selected: [],
      processing: false,
    };
  },
  mounted() {},
  setup() {
    const {
      fetchStudents,
      tableColumns,
      perPage,
      currentPage,
      totalPageCount,
      totalStudents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStudentListTable,
      refetchData,

      // UI

      resolveStudentRoleIcon,
      resolveStudentStatusVariant,

      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
    } = useStudentsBlacklist();

    return {
      fetchStudents,
      tableColumns,
      perPage,
      currentPage,
      totalPageCount,
      totalStudents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStudentListTable,
      refetchData,

      // Filter
      avatarText,

      // UI

      resolveStudentRoleIcon,
      resolveStudentStatusVariant,

      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,

      //
      momentTz,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
