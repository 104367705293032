import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';

export default function useStudentsBlacklist() {
  // Use toast
  const toast = useToast();

  const refStudentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'student', sortable: true },
    { key: 'blacklist.reason', label: 'Reason', sortable: true },
    { key: 'blacklist.timestamp', label: 'Date', sortable: true },
    // {
    //   key: 'currentPlan',
    //   label: 'Plan',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalStudents = ref(0);
  const currentPage = ref(1);
  const totalPageCount = ref(0);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('blacklist.timestamp');
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refStudentListTable.value ? refStudentListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStudents.value,
    };
  });

  const refetchData = () => {
    refStudentListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, roleFilter, visibilityFilter, statusFilter], () => {
    refetchData();
  });

  const fetchStudents = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    store
      .dispatch('studentStoreModule/fetchProfiles', {
        search: searchQuery.value,
        limit: perPage.value,
        // sortDesc: isSortDirDesc.value,
        page: currentPage.value,
        sortBy: sb,

        blacklist: true,
        populate: 'student',

        status: statusFilter.value,
      })
      .then((response) => {
        const { results, totalResults, totalPages } = response.data;

        callback(results);
        totalStudents.value = totalResults;
        totalPageCount.value = totalPages;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching students list-pending',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // const resolveStudentRoleVariant = (role) => {
  //   if (role === 'student') return 'info';
  //   if (role === 'staff') return 'warning';
  //   if (role === 'proctor') return 'info';
  //   if (role === 'accountant') return 'success';
  //   if (role === 'admin' || role === 'manager') return 'danger';
  //   return 'info';
  // };

  const resolveStudentRoleIcon = (role) => {
    return 'UserIcon';
  };

  const resolveStudentStatusVariant = (status) => {
    if (status === 'pending') return 'warning';
    if (status === 'active') return 'success';
    if (status === 'inactive') return 'secondary';
    return 'info';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Enrolling', value: 'enrolling' },
    { label: 'Enrolled', value: 'enrolled' },
    { label: 'In-Class', value: 'in_class' },
    { label: 'Placed', value: 'placed' },
    { label: 'Archived', value: 'archived' },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Student', value: 'student' },
  ];

  const clearanceOptions = [
    { label: 'None', value: 'none' },
    { label: 'Public Trust', value: 'public_trust' },
    { label: 'Confidential', value: 'confidential' },
    { label: 'Secret', value: 'secret' },
    { label: 'Top Secret', value: 'top_secret' },
    { label: 'TS + SCI', value: 'ts_sci' },
    { label: 'TS + POLY', value: 'ts_poly' },
    { label: 'TS + POLY + SCI', value: 'ts_poly_sci' },
  ];

  const relocatableOptions = [
    { label: 'No', value: 'no' },
    { label: 'Conditionally', value: 'conditionally' },
    { label: 'Yes', value: 'yes' },
  ];

  const remoteOptions = [
    { label: 'No', value: 'no' },
    { label: 'Conditionally', value: 'conditionally' },
    { label: 'Yes', value: 'yes' },
  ];

  return {
    fetchStudents,
    tableColumns,
    perPage,
    currentPage,
    totalStudents,
    totalPageCount,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStudentListTable,

    resolveStudentRoleIcon,
    resolveStudentStatusVariant,
    refetchData,

    statusOptions,
    visibilityOptions,
    clearanceOptions,
    relocatableOptions,
    remoteOptions,

    // Extra Filters
    roleFilter,
    visibilityFilter,
    statusFilter,
  };
}
