<template>
  <div>
    <student-list-add-new
      v-if="$can('student.create')"
      :is-add-new-student-modal-active.sync="isAddNewStudentModalActive"
      @refetch-data="refetchData"
    />

    <div class="content-px mb-75">
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center justify-content-start flex-wrap flex-sm-nowrap" style="gap: 0.675rem">
              <b-form-input v-model="searchQuery" class="drop-shadow-default" placeholder="Search..." style="max-width: 28rem" />
              <students-list-filters :status-filter.sync="statusFilter" :status-options="statusOptions" />
            </div>

            <b-button
              v-if="$can('student.create')"
              variant="primary"
              @click="newStudent"
              class="ml-1"
              style="line-height: 1rem; font-size: 1rem"
            >
              <feather-icon icon="PlusCircleIcon" size="16" class="mr-25" />
              <span class="text-nowrap">New Student</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <div no-body class="mb-0 table-no-top">
      <b-table
        ref="refStudentListTable"
        class="position-relative mb-0"
        :items="fetchStudents"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @row-contextmenu="onRowContextMenu"
        animation
      >
        <!-- Column: Student -->
        <template #cell(fullName)="data">
          <div class="d-flex align-items-center" style="gap: 0.625rem">
            <b-avatar
              size="36"
              :src="data.item.avatar"
              :text="avatarText(`${data.item.firstName} ${data.item.lastName}`)"
              :variant="`light-info`"
              :to="{ name: 'apps-students-view', params: { id: data.item.id } }"
            />
            <div class="d-flex flex-column" style="gap: 0">
              <b-link
                :to="{ name: 'apps-students-view', params: { id: data.item.id } }"
                class="font-weight-bold text-nowrap text-white mb-0"
                style="font-size: 1.25rem; line-height: 1.75rem"
              >
                {{ data.item.fullName }}
              </b-link>
              <small class="text-muted" style="font-size: 1.05rem; line-height: 1.35rem">{{ data.item.myIpId }}</small>
            </div>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(phoneNumber)="data">
          <div class="d-flex align-items-center" style="gap: 0.4rem" v-if="data.item.phoneNumber">
            <Flag
              v-if="resolveStudentPhoneDetails(data.item.phoneNumber).country"
              :code="resolveStudentPhoneDetails(data.item.phoneNumber).country"
              size="m"
              :hasBorderRadius="true"
              :hasBorder="false"
            />
            <span class="text-capitalize mb-0">{{ resolveStudentPhoneDetails(data.item.phoneNumber).formatNational() }}</span>
          </div>
          <span v-else class="text-muted">None</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge :variant="`${resolveStudentStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="primary" class="dropdown-edit-button">
            <template #button-content>
              <div class="p-50">
                <feather-icon icon="MoreHorizontalIcon" size="21" />
              </div>
            </template>

            <b-dropdown-item
              v-if="$can('student.update')"
              @click="$router.push({ name: 'apps-students-edit', params: { id: data.item.id } })"
            >
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>Edit</span>
                <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>

            <b-dropdown-item v-if="$can('student.get')" @click="$router.push({ name: 'apps-students-view', params: { id: data.item.id } })">
              <div class="d-flex align-items-center justify-content-between w-100">
                <span>View</span>
                <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-50">
        <b-row>
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="mb-0 text-muted">{{ dataMeta.of }} Total Entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" md="9" class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1 mt-sm-0">
            <div class="mr-2 vue-select-up">
              <span class="mb-0 mr-75">Rows Per Page</span>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
                :open="true"
              />
            </div>
            <span class="mr-2 ml-lg-50 mb-0">Page {{ currentPage }} of {{ totalPageCount }}</span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-sm-0 ml-lg-50"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>

    <vue-context ref="studentListMenu" class="mw-8" v-slot="{ data }">
      <li v-if="data">
        <a
          class="d-flex align-items-center justify-content-between"
          v-if="$can('student.update')"
          @click="$router.push({ name: 'apps-students-edit', params: { id: data.item.id } })"
        >
          <span>Edit</span>
          <feather-icon icon="Edit3Icon" size="15" class="ml-50 text-muted" />
        </a>
        <a
          v-if="$can('student.get')"
          class="d-flex align-items-center justify-content-between"
          @click="$router.push({ name: 'apps-students-view', params: { id: data.item.id } })"
        >
          <span>View</span>
          <feather-icon icon="EyeIcon" size="15" class="ml-50 text-muted" />
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import StudentsListFilters from './StudentsListFilters.vue';
import useStudents from '../useStudents';
import studentStoreModule from '../../../../store/student/studentStoreModule';
import StudentListAddNew from './StudentListAddNew.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import VueContext from 'vue-context';

export default {
  components: {
    StudentsListFilters,
    StudentListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect,
    VueContext,
  },

  methods: {
    archiveStudent(studentId) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to archive this student?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remove',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          store
            .dispatch('studentStoreModule/updateStudent', {
              id: studentId,
              studentData: { status: 'archived' },
            })
            .then((response) => {
              this.refetchData();
            });
        }
      });
    },
    doExport(ids) {
      this.$http
        .post('v1/jobs/export', { entity: 'resume', metadata: { ids } })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,

            props: {
              title: 'Job Started',
              icon: 'SettingsIcon',
              variant: 'success',
              text: 'Your job has been queued for execution. You may leave the site while it is prepared.',
            },
          });

          this.$router.push({ name: 'apps-job-list-pending' });
        })
        .catch((e) => {
          this.processing = false;
          console.error(e);
        });
    },
    async doAllPdfExport() {
      this.processing = true;
      try {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Job Build Started',
            icon: 'SettingsIcon',
            variant: 'success',
            text: 'This may take a moment to generate the job settings, please wait...',
          },
        });

        const { searchQuery, statusFilter, pathwayFilter, createAtStartFilter, createAtEndFilter } = this;

        const pl = {
          search: searchQuery,
          limit: 100,
        };

        if (statusFilter) {
          pl.status = statusFilter;
        }
        if (pathwayFilter) {
          pl.programName = pathwayFilter;
        }
        // if (createAtStartFilter) {
        //   pl.createAtStart = createAtStartFilter;
        // }
        // if (createAtEndFilter) {
        //   pl.createAtEnd = createAtEndFilter;
        // }

        let currentPage = 1;
        let lastPage = 2;

        let allIds = [];
        let currentFail = 0;

        while (currentPage <= lastPage || currentFail > 5) {
          try {
            const response = await this.selectNextPage(currentPage, pl);

            if (response.status !== 200) {
              currentPage -= 1;
              currentFail += 1;
              continue;
            }

            const { results, totalPages } = response.data;

            console.log(results);
            results.forEach((report) => allIds.push(report.id));

            lastPage = totalPages;
            currentPage += 1;
            currentFail = 0;
          } catch (e) {
            currentFail += 1;
            currentPage -= 1;
          }
        }

        allIds = allIds.filter((value, index, self) => self.indexOf(value) === index);

        this.doExport(allIds);
      } catch (e) {
        console.error(e);
      }

      this.processing = false;
    },
    editStudent(data) {
      this.editStudentData = data;
      this.editActive = true;
    },
    onRowContextMenu(item, index, event) {
      this.$refs.studentListMenu.open(event, { item });
      event.preventDefault();
    },
    async selectNextPage(page, payload) {
      return new Promise((resolve, reject) => {
        store
          .dispatch('studentStoreModule/fetch', { ...payload, page })
          .then(resolve)
          .catch(reject);
      });
    },
    newStudent() {
      this.isAddNewStudentModalActive = true;
    },
  },

  data: () => {
    return {
      selected: [],
      processing: false,
    };
  },
  mounted() {
    this.$root.$on('fireRootEventN', this.newStudent);
    if (this.$route.query.create === 'true') {
      this.newStudent();
    }

    if (this.$route.query.newStudent === 'true') {
      this.isAddNewStudentModalActive = true;
    }
  },
  beforeDestroy() {
    this.$root.$off('fireRootEventN', this.newStudent);
  },
  setup() {
    const editStudentData = ref({});
    const editActive = ref(false);

    const isAddNewStudentModalActive = ref(false);

    const {
      fetchStudents,
      tableColumns,
      perPage,
      currentPage,
      totalPageCount,
      totalStudents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStudentListTable,
      refetchData,

      // UI
      resolveStudentStatusVariant,
      resolveStudentPhoneDetails,

      statusOptions,

      // Extra Filters
      statusFilter,
    } = useStudents();

    return {
      // Sidebar
      isAddNewStudentModalActive,

      fetchStudents,
      tableColumns,
      perPage,
      currentPage,
      totalPageCount,
      totalStudents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refStudentListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveStudentStatusVariant,
      resolveStudentPhoneDetails,

      statusOptions,

      // Extra Filters
      statusFilter,

      editStudentData,
      editActive,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
