<template>
  <div class="pt-75">
    <!-- <h2 class="content-header-title mb-50 px-1">{{ $route.meta.pageTitle }}</h2> -->

    <b-tabs class="mb-0" nav-wrapper-class="content-px" nav-class="mb-50">
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <lucide-icon name="Users" style="width: 17px; height: 17px" />
          <span class="d-none d-sm-inline">All Students</span>
          <span class="d-block d-sm-none">All</span>
        </template>
        <students-list />
        <!-- <cohort-edit-tab-information :cohort-data.sync="cohortData" class="mt-2 pt-75" /> -->
      </b-tab>
      <b-tab>
        <template #title>
          <font-awesome-icon icon="fa-regular fa-face-swear" />
          <span class="d-none d-sm-inline">Blacklisted</span>
          <span class="d-block d-sm-none">Blacklisted</span>
        </template>
        <students-blacklist />
        <!-- <cohort-edit-tab-information :cohort-data.sync="cohortData" class="mt-2 pt-75" /> -->
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTabs,
  BTab,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import VueContext from 'vue-context';
import StudentsBlacklist from '../students-blacklist/StudentsBlacklist.vue';
import StudentsList from './StudentsList.vue';

export default {
  components: {
    StudentsBlacklist,
    StudentsList,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,

    vSelect,
    VueContext,
  },
  methods: {},
  metaInfo() {
    return {
      title: 'Students',
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
