<template>
  <b-modal
    id="add-new-student-modal"
    :visible="isAddNewStudentModalActive"
    @hidden="closeModal"
    size="lg"
    :busy="busy"
    title="Add New Student"
    centered
    body-class="pt-0"
    hide-footer
  >
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form class="" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
        <b-row>
          <b-col cols="12" md="6">
            <!-- First Name -->
            <validation-provider #default="validationContext" name="First Name" rules="required">
              <b-form-group label="First Name" label-for="first-name">
                <b-form-input
                  id="first-name"
                  v-model="studentData.firstName"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="John"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <!-- last name -->
            <validation-provider #default="validationContext" name="Last Name" rules="required">
              <b-form-group label="Last Name" label-for="last-name">
                <b-form-input
                  id="last-name"
                  v-model="studentData.lastName"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Doe"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Phone Number -->
        <validation-provider v-if="false" #default="validationContext" name="Phone Number" rules="regex:^([0-9]+)$">
          <b-form-group label="Phone Number" label-for="phoneNumber">
            <b-form-input
              id="phoneNumber"
              v-model="studentData.phoneNumber"
              :state="getValidationState(validationContext)"
              trim
              placeholder="17035543827"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Email -->
        <validation-provider #default="validationContext" name="Email" rules="required|email">
          <b-form-group label="Email" label-for="email">
            <b-form-input
              id="email"
              v-model="studentData.email"
              :state="getValidationState(validationContext)"
              trim
              placeholder="j.doe@email.com"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex mt-2 mb-25 justify-content-end">
          <b-button type="button" variant="outline-secondary" @click="closeModal" :disabled="busy"> Cancel </b-button>
          <b-button variant="primary" class="ml-1" type="submit" :disabled="busy"> Create Student </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BRow, BCol } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { v4 } from 'uuid';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { useToast } from 'vue-toastification/composition';
import { useRouter } from '@core/utils/utils';
import store from '@/store';

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isAddNewStudentModalActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  watch: {
    isAddNewStudentModalActive(val) {
      if (val === true) {
        this.resetForm();
      }
    },
  },
  setup(props, { emit }) {
    const blankStudentData = {
      studentId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    };

    const toast = useToast();

    const studentData = ref(JSON.parse(JSON.stringify(blankStudentData)));
    const busy = ref(false);

    const resetstudentData = () => {
      studentData.value = JSON.parse(JSON.stringify(blankStudentData));
    };

    const { router, route } = useRouter();

    const closeModal = () => {
      busy.value = false;
      emit('update:isAddNewStudentModalActive', false);
    };

    const onSubmit = () => {
      busy.value = true;

      if (studentData.value) {
        studentData.value.password = v4();
      }

      store
        .dispatch('studentStoreModule/addStudent', studentData.value)
        .then((response) => {
          emit('refetch-data');
          closeModal();
          return router.push(`/students/${response.data.id}`);
        })
        .catch((error) => {
          busy.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetstudentData);

    return {
      studentData,
      onSubmit,
      closeModal,

      refFormObserver,
      getValidationState,
      resetForm,
      busy,
    };
  },
};
</script>

<style lang="scss">
#add-new-student-modal {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
